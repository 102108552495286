// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import BookingList from './components/BookingList';
import RentalList from './components/RentalList';
import Dashboard from './components/Dashboard';
import PrivateRoute from './PrivateRoute';
import APIKeyList from './components/APIKeyList';
import GuestDashboard from './components/GuestDashboard';
import './index.css'

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/guest/:id/dashboard" element={<GuestDashboard  />} />
          <Route path="/dashboard"element={<PrivateRoute><Dashboard /></PrivateRoute>}/>
          <Route path="/bookings"element={<PrivateRoute><BookingList /></PrivateRoute>}/>
          <Route path="/rentals"element={<PrivateRoute><RentalList /></PrivateRoute>}/>
          <Route path="/apikeys"element={<PrivateRoute><APIKeyList /></PrivateRoute>}/>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
